import React, {useEffect, useState} from 'react';
import Layout from "../components/layout";
import {graphql} from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image";
import {formatDate} from "../utils/commonUtil";
import {Navigate} from "@cg-squad/ui-components";

// data
const missions = [
    {
        icon: 'icon1.png',
        head: 'AgeMatch',
        body: 'We know that seniors are not a homogenous group. Each segment within the seniors demographic has different needs, demands, and requirements. Using our AI based Machine Learning matching engine partners and seniors are matched.'
    },
    {
        icon: 'icon2.png',
        head: 'AgeConnect',
        body: 'A SSO solution offering seniors an easy to use single sign on that provides them with a seamless experience when interacting with our partners.'
    },
    {
        icon: 'icon3.png',
        head: 'AgeVerse',
        body: 'Nearly 1 in 4 Americans is regarded as socially isolated. We harness technology to get people who are struggling or incapable of meeting others to meet each other in the AgeVerse.'
    }
]

// markup
const IndexPage = (props) => {
    const [featuredNews, setFeaturedNews] = useState([]);
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const totalItems = props.data.allDatoCmsArticle.nodes.length;
        setFeaturedNews(props.data.allDatoCmsArticle.nodes.slice(0, 2));
        setBlogs(props.data.allDatoCmsArticle.nodes.slice(Math.max(totalItems - 3, 0)));
    }, [props.data.allDatoCmsArticle]);

    return (
        <Layout>
            <div className="wrapper">
                <div className="flex justify-center flex-wrap top-0 relative">
                    <div className="relative">
                        <img src={"/images/hero.jpeg"} width={'100%'} alt={"hero"} className=""/>
                        <div className="home-hero-mage">
                            <div className={"text"}>Bringing life to years</div>
                        </div>
                    </div>

                    <div className="our-mission md:w-4/5 text-center relative md:bottom-[90px]">
                        <h1 className="text-2xl lg:text-4.5xl font-bold bg-blue-50 text-white py-2">Our Mission</h1>
                        <p className="bg-gray-50 p-8 text-left text-lg">AgeGroup is the premier source of information, activities and products for seniors in an inclusive and non-political environment.
                            <br/>Through our digital universe our partners can access highly targeted communities and seniors based on AgeGroup’s first party data.</p>
                    </div>

                    <div className="flex flex-col lg:flex-row">
                        {missions.map(mission => {
                            return (
                                <div className="text-center md:mr-4 mt-8 md:mt-0 flex-1" key={mission.head}>
                                    <img src={`/images/icons/${mission.icon}`} alt={mission.head} className="mx-auto w-[70px]"/>
                                    <h2 className="text-xl font-bold mt-2 mb-8">{mission.head}</h2>
                                    <p className="text-lg text-left px-8 lg:px-0">{mission.body}</p>
                                </div>
                            )
                        })}
                    </div>

                    <div className="flex flex-col md:flex-row items-center justify-center md:w-4/5 md:mt-24 mt-32 relative">
                        <img src={"/images/2.jpg"} width={300} height={'auto'} alt={"our dream"} className="md:w-[420px] w-full"/>
                        <div className="lg:ml-5">
                            <h1 className="our-dreams text-4.5xl font-bold">Our Dream</h1>
                            <p className="text-lg text-left mt-2 md:p-0 p-6">To create a world where ageism is a thing of the past and digital inclusion is a realty.
                            <br/>We want to make sure that every senior has <i className={"font-semibold"}>unlimited access</i> to information, activities and products that are relevant to them, through the most relevant platform in an inclusive and non-political manner.
                            <br/>We dream of annihilating ageism in the workplace, getting employers to understand that older employees bring several benefits.</p>
                        </div>
                    </div>
                    <div className="md:w-4/5 mt-12 relative">
                        <div className="flex items-center">
                            <h1 className="relative text-4.5xl font-bold text-gray-51 news-title shrink-0">
                                Featured News
                            </h1>
                            <span className="head-line"></span>
                        </div>
                        <div className="flex flex-wrap justify-between mt-[2.125rem] md:ml-[5%]">
                            {featuredNews.map(article => {
                                return (
                                    <div className="md:w-9/20" key={article.slug}>
                                        <Navigate href={`/blogs/${article.slug}`}>
                                            <GatsbyImage className={""}
                                                         alt={article.heading}
                                                         image={article.thumbnail ? article.thumbnail.gatsbyImageData : article.gatsbyImageData}/>
                                            <div className="w-11/12 bg-gray-50 p-5 relative lg:top-[-18%] top-[-10%] mx-auto">
                                                <div
                                                    className="bg-pink-50 w-1/2 rounded-[20px] p-[3px] text-2xl text-white text-center font-bold">Blog</div>
                                                <h1 className="font-bold text-4.5xl mt-4">{article.title}</h1>
                                                <p className="mt-4 text-lg line-clamp l-c-4">{article.excerpt}</p>
                                                <div className="line"></div>
                                                <div className="text-base lg:text-lg">
                                                    <time>{formatDate(article.publishedAt, "mmmm dd, yyyy")}</time><span
                                                    className="ml-4 text-pink-50">{article.author.name}</span>
                                                </div>
                                            </div>
                                        </Navigate>
                                    </div>
                                )
                            })}
                        </div>
                        <Navigate className="text-pink-50 text-2xl cursor-pointer font-bold text-center block underline mx-auto mt-[-8%] mb-8"
                                  href={"/blogs"}>
                            Discover More
                        </Navigate>
                    </div>
                    <div className="mt-7 relative hidden md:block">
                        <img src="/images/5.jpg" alt={"quote"}/>
                        <div className="w-[35%] h-[340px] bg-blue-50 text-white relative p-5 left-[58%] bottom-[90px]">
                            <div className="inverted-comma text-9xl h-[40px] leading-[100px] italic font-bold">"</div>
                            <div className="text-4.5xl font-bold mt-2">Young people run faster, seniors know the way…</div>
                            <div
                                className="inverted-comma text-9xl text-right italic font-bold leading-[15px]">"
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:bg-gray-50 pb-20">
                <div className="wrapper">
                    <div className={"md:w-4/5 mx-auto"}>
                        <div className="flex items-center pt-8">
                            <h1 className="relative text-4.5xl font-bold text-gray-51 news-title shrink-0">
                                Blog
                            </h1>
                            <span className="head-line"></span>
                        </div>
                        <div className="flex flex-wrap mt-6 justify-between md:justify-start">
                            {blogs.map(blog => {
                                return (
                                    <div className="md:w-[32%] w-[48%] md:mr-2 mt-4" key={blog.slug}>
                                        <GatsbyImage className={""}
                                                     alt={blog.heading}
                                                     image={blog.thumbnail ? blog.thumbnail.gatsbyImageData : blog.gatsbyImageData}/>
                                        <div className="bg-white p-5" style={{minHeight: 220}}>
                                            <div className="text-blue-50 text-xl font-bold">Blog</div>
                                            <h1 className="text-2xl font-bold">
                                                <Navigate href={`/blogs/${blog.slug}`}>{blog.heading}</Navigate>
                                            </h1>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query {
        allDatoCmsArticle(
            filter: {website: {elemMatch: {name: {eq: "age-group"}}}}
            sort: {order: DESC, fields: publishedAt}
            limit: 5
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`

export default IndexPage
